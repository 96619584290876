<template>
  <div class="app-container">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker>
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" @keyup.enter="getList" />
        <el-button class="filter-item1" type="primary" size="small" plain @click="getList">搜索</el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:700px" class="styleForm">
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
              <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
            </el-form-item>
            <el-form-item label="采集状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" plain type="primary" :loading="this.loading" @click="declaration()">批量采集</el-button>
      </div>
    </div>
    <div class="none-border">
      <el-table :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" min-width="50" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="180" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            <span style="cursor: pointer;color:#39b0d2" @click="goEtax(scope.row)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="left" prop="districtName" label="申报税种" min-width="180"
        :filters="this.categories">
          <template #default="scope">
            <div class="div_p">
              <p style="text-align: left;">{{$tableNameFilter(scope.row.tableName)}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="应补（退）税额" min-width="100">
          <template #default="scope">
            <div class="div_p">
              <p>{{scope.row.tax}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="取数" min-width="100">
          <template #default="scope">
              <p v-if="scope.row.gsMainStatus == '1'">已取数</p>
              <p v-if="scope.row.gsMainStatus == '2'">未取数</p>
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="districtName" label="采集状态" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.taskStatus)}}
                </p>
                <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusCj(scope.row.taskStatus)}}
                </p>
                <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusCj(scope.row.taskStatus)}}</p>
                <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.errLog" placement="top-start">
                  <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusCj(scope.row.taskStatus)}}</el-button>
                </el-tooltip>
                </p>
                <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-gantanhao"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.errLog" placement="top-start">
                  <el-button size="small" class="sbyc" style="border: 0;">{{$setUpStatusCj(scope.row.taskStatus)}}</el-button>
                </el-tooltip>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button @click="handleUpdate(scope.row)" size="small" type="text">采集</el-button>
              <el-button @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
  <!-- 采集弹窗 -->
  <collectGather ref="collectGather" @success="getList"></collectGather>
</template>

<script>
import { companyInitTaxList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import collectGather from "./collectGather.vue";
// import selectuser from "@/components/Screening/selectuser";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
export default {
  name: "batchSendSb",
  components:{
    selecttaxtype,
    // selectuser
    declareDetails,
    collectGather
  },
  data(){
    return {
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        // status:'2',
        taskName:'tax-cj-inInvoice',
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        accountingType: 2,
        taxNames:[],
        status: 2,
      },
      total:0,
      list:[],
      gsMainIds:[],
      loading:false,
      options: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        }
      ],
      categories: [
        {text: '利润表', value: 'kj_lrb'}, 
        {text: '资产负债表', value: 'kj_zcfz'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '工会经费', value: 'gs_ghjf'},
        {text: '房产税土地税', value: 'gs_fcs'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_cb'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_jb'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '工会经费', value: 'gs_qtsr'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
      ],
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(240)
  },
  methods:{
    getList(){
      companyInitTaxList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleClick() {
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(item=>{
        this.gsMainIds.push( item.comId )
      })
      // console.log(this.gsMainIds,'this.gsMainIds')
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,e.taskName)
    },
    //批量采集
    declaration() {
      if(this.gsMainIds.length == 0) {
        this.$qzfMessage("至少选择一家公司", 1)
        return
      }
      //api
      // this.$qzfMessage("采集中", 3)
      this.$refs.collectGather.init(this.gsMainIds,this.listQuery.period)
    },
    handleUpdate(e) {
      this.$refs.collectGather.init([e.comId],this.listQuery.period,e.taskName)
    },
    goEtax(row){
      this.$goEtax(row)
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
</style>