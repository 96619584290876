<template>
    <collectInvoiceXx ref="batchSendSb" :activeName = "activeName" ></collectInvoiceXx>
</template>

<script>
import collectInvoiceXx from "./components/collectInvoiceXx"
import collectInvoiceJx from "./components/collectInvoiceJx"
export default {
  name:'collectInvoice',
  components:{
    collectInvoiceXx,
    // collectInvoiceJx
  },
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init',
        userIds:[]
      },
      userRoleName:this.$store.getters['user/user'].userRoleName,
    }
  },
  created(){
  },
  methods:{
    handleClick(tab,event){
      this.listQuery.userIds = []
      if (tab.props.label == "学生申报") {
        this.$refs.batchSendSb.getList(1)
      } else if (tab.props.label == "我的申报") {
        this.$refs.batchSendSb.getList(2)
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
      } 
    }
  }
}
</script>

<style lang="scss" scoped>

</style>