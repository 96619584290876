<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="我的企业" name="我的企业"></el-tab-pane>
      <el-tab-pane label="学生企业" name="学生企业" v-if="this.userRoleName != '学生'"></el-tab-pane>
   <!-- todo -->
      <div class="top_btns">
        <div class="left_box">
          <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
          <!-- <selectcity v-model:citys="listQuery.districtCode" @success="getList()"></selectcity> -->
          <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList" />
          <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
            <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <search @success="getList">
            <el-form style="margin:11px 0px 0px 6px;width:800px" class="styleForm">
              <el-form-item label="地区 ：" :label-width="formLabelWidth">          
                <addressList v-model:type="listQuery.address"></addressList>
              </el-form-item>
              
              <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
                <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
              </el-form-item>

              <el-form-item label="选择班级 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生'">   
                <selectClass v-model:deptId="listQuery.deptId" @success="getUserList" style="margin-left:5px"></selectClass>
              </el-form-item>

              <el-form-item label="选择人员 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生'">          
                <selectuser ref="selectuser" code="bookkeeping" eduCode="" v-model:userId="listQuery.userIds" @success="getUser"></selectuser>
              </el-form-item>
              
              <!-- <el-form-item label="报税手机号 ：" :label-width="formLabelWidth">          
                <el-input v-model="listQuery.tel" size="small" style="width:130px"></el-input>
              </el-form-item> -->

              <!-- <el-form-item label="进项采集任务状态：" :label-width="formLabelWidth">
                <selectCjTaskStatus v-model:taskStatus="listQuery.inTaskStatus"></selectCjTaskStatus>
              </el-form-item> -->

              <el-form-item label="进项采集任务结果：" :label-width="formLabelWidth">
                <selectBussinessCjStatus v-model:allStatus="listQuery.inAllStatus"></selectBussinessCjStatus>
              </el-form-item>
  <!-- 
              <el-form-item label="销项采集任务状态：" :label-width="formLabelWidth">
                <selectCjTaskStatus v-model:taskStatus="listQuery.outTaskStatus"></selectCjTaskStatus>
              </el-form-item> -->

              <el-form-item label="销项采集任务结果：" :label-width="formLabelWidth">
                <selectBussinessCjStatus v-model:allStatus="listQuery.outAllStatus"></selectBussinessCjStatus>
              </el-form-item>
              
            </el-form>
          </search>
        </div>
        <div class="right_box">
          <el-button size="small" type="primary" @click="declaration()"   >
            <el-icon><Aim /></el-icon><span  > 批量采集</span>
          </el-button>


          <el-dropdown size="small" style="margin-left:10px;margin-right:10px" :hide-on-click="false">
            <el-button size="small" type="primary">
              更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu style="text-align:center">
                <el-dropdown-item command="a">
                  <span @click="cancelTask('in')">撤销进项采集</span>
                </el-dropdown-item>
                <el-dropdown-item command="b">
                  <span @click="cancelTask('out')">撤销销项采集</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
      
        </div>
      </div>
      <div class="none-border">
        <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column align="center" prop="id" type="selection" min-width="55" />
          <el-table-column align="center" type="index" label="编号" min-width="50" fixed/>
          <!-- <el-table-column align="center" prop="districtCode" label="企业编码" min-width="100">
            <template #default="scope">
              <p class="details">{{scope.row.districtCode}}</p>
            </template>
          </el-table-column> -->
          <el-table-column align="left" prop="name" label="公司名称" min-width="280" class="gsmc" fixed>
            <template #default="scope">
              <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
              <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
              <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.comId)">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="inAmount1" label="地区" min-width="120">
            <template #default="scope">
              <span >{{$cityFilter(scope.row.address)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="inAmount1" label="进项采集总金额" min-width="120">
            <template #default="scope">
              <span v-if="scope.row.inAmount1">{{parseFloat(scope.row.inAmount1).toFixed(2)}}</span>
              <span v-else>{{'0.00'}}</span>
            </template>
          </el-table-column>


          <el-table-column align="center" prop="districtName" label="进项采集任务状态" min-width="250">
            <template #default="scope">
              <div class="div_p">
                <div class="item_icon">
                  <p>
                    <el-tooltip :disabled="(scope.row.inErrLog?scope.row.inErrLog:'' + scope.row.inBusinessLog?scope.row.inBusinessLog:'') == ''?true:false" class="item" effect="dark" :content="scope.row.inErrLog?scope.row.inErrLog:'' + scope.row.inBusinessLog?scope.row.inBusinessLog:''" placement="top-start">
                      <i :class="$batchSendIconCj(scope.row.inTaskStatus, scope.row.inBusinessStatus)">{{$batchSendCj(scope.row.inTaskStatus, scope.row.inBusinessStatus)}}
                        <i class="iconfont icon-xiaoxizhongxin" v-if="scope.row.inErrLog || scope.row.inBusinessLog" style="font-size:15px;color: red;"></i>
                      </i>
                    </el-tooltip>
                  </p>
                  <span>{{scope.row.inEndAt}}</span>
                </div>
                <p style="padding-top: 12px;"><el-button @click="declareDetail1(scope.row)" size="small" type="text">采集记录</el-button></p>
              </div>
            </template>
          </el-table-column>


          <el-table-column align="center" prop="inSource" label="进项来源" min-width="140">
            <template #default="scope">
                {{scope.row.inSource}}
            </template>
          </el-table-column>

          <el-table-column align="center" prop="outAmount1" label="销项采集总金额" min-width="120">
            <template #default="scope">
              <span v-if="scope.row.outAmount1">{{parseFloat(scope.row.outAmount1).toFixed(2)}}</span>
              <span v-else>{{'0.00'}}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="districtName" label="销项采集任务状态" min-width="250">
            <template #default="scope">
              <div class="div_p">

                <div class="item_icon">
                  <p>
                    <el-tooltip :disabled="(scope.row.outErrLog?scope.row.outErrLog:'' + scope.row.outBusinessLog?scope.row.outBusinessLog:'') == ''?true:false" class="item" effect="dark" :content="scope.row.outErrLog?scope.row.outErrLog:'' + scope.row.outBusinessLog?scope.row.outBusinessLog:''" placement="top-start">
                      <i :class="$batchSendIconCj(scope.row.outTaskStatus, scope.row.outBusinessStatus)">{{$batchSendCj(scope.row.outTaskStatus, scope.row.outBusinessStatus)}}
                        <i class="iconfont icon-xiaoxizhongxin" v-if="scope.row.outErrLog || scope.row.outBusinessLog" style="font-size:15px;color: red;"></i>
                      </i>
                    </el-tooltip>
                  </p>
                  <span>{{scope.row.outEndAt}}</span>
                </div>
                <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button></p>
              </div>
            </template>
          </el-table-column>


          <el-table-column align="center" prop="outSource" label="销项来源" min-width="140">
            <template #default="scope">
                {{scope.row.outSource}}
            </template>
          </el-table-column>

          <el-table-column align="center" prop="manager" label="操作" min-width="220">
            <template #default="scope">
              <div class="div_p1">
                <el-button @click="handleUpdate(scope.row)" size="small" type="text">采集</el-button>
                <el-button @click="goCheck(scope.row)" size="small" type="text" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="打开中请稍后...">勾选认证</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </el-tabs>

  </div>
  <!-- 采集记录 -->
  <declareDetails ref="declareDetails"></declareDetails>
  <!-- 采集弹窗 -->
  <collectGather ref="collectGather" @success="getList"></collectGather>
</template>

<script>
import { companyInvoiceList ,loginTaxInfo } from "@/api/company"

import selecttaxtype from "@/components/Screening/selecttaxtype";
import addressList from "@/components/Screening/address";
import selectcity from "@/components/Screening/selectcity";

import declareDetails from "./declareDetails";
import collectGather from "./collectGather.vue";
import { currentAccountPeriod } from '@/utils'
import axios from 'axios';
import { quitTask } from "@/api/collection"
import selectCjTaskStatus from "./select/selectCjTaskStatus.vue"
import selectBussinessCjStatus from "./select/selectBussinessCjStatus.vue"


export default {
  name: "collectInvoiceXx",
  components:{
    addressList,
    selecttaxtype,
    declareDetails,
    collectGather,
    selectcity,
    selectCjTaskStatus,
    selectBussinessCjStatus
  },
  data(){
    return {
      activeName:'我的企业',
      listQuery: {
        page: 1,
        limit: 20,
        name: '',
        period: currentAccountPeriod(),
        inTaskStatus: [],
        inBusinessStatus:[],
        outTaskStatus: [],
        outBusinessStatus:[],
        inAllStatus: [],
        outAllStatus: [],
        accountingType: 2,
        taxNames:[],
        address:"",
        userIds:[this.$store.getters['user/user'].userId]
      },
      formLabelWidth:'140px',
      total:0,
      list:[],
      gsMainIds:[],
      pageStatus: true,

      gsMainType: [],  //企业类型
      gsMainAddress:[], //企业地区
      inReqNos: [],
      outReqNos: [],
      option: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        }
      ],
      options: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        }
      ],
      fullscreenLoading:false,
      userRoleName:this.$store.getters['user/user'].userRoleName
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(340)
    this.initBus()
  },
  methods:{
    cancelTask(type){
      let reqNos = this.inReqNos
      if(type == 'out'){
        reqNos = this.outReqNos
      }
      if(reqNos.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定撤销采集任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        quitTask({reqNos:reqNos}).then(res => {
          if(res.data.msg == 'success') {
            this.$cancelMsg(res.data.data.list)
            this.getList()
          }
        })
      })
    },
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.outTaskStatus == 1 || res.outTaskStatus == 2 || res.inTaskStatus == 1 || res.inTaskStatus == 2) {
            status = true
          }
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    getList(a){
      if(a == 1 || a ==2){
        this.listQuery.accountingType = a
      }
      companyInvoiceList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleClick(tab) {
      this.listQuery.userIds = []
      if(tab.props.name == "我的企业") {
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
        this.getList()
      }if(tab.props.name == "学生企业") {
        this.getList()
      }
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      this.gsMainAddress =[]
      this.gsMainType = []
      this.inReqNos = []
      this.outReqNos = []
      // gsMainType
      row.map(item=>{
        this.gsMainIds.push(item.comId)
      })
      row.map(item=>{
        this.gsMainType.push(item.type)
      })
      row.map(item=>{
        this.gsMainAddress.push(item.address)
      })
      row.map(item=>{
        this.inReqNos.push(item.inReqNo)
      })
      row.map(item=>{
        this.outReqNos.push(item.outReqNo)
      })
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,'tax-cj-outInvoice')
    },
    declareDetail1(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,'tax-cj-inInvoice')
    },
    //批量采集
    declaration() {
      if(this.gsMainIds.length == 0) {
        this.$qzfMessage("至少选择一家公司", 1)
        return
      }
      function isAllEqual(array) {
        if (array.length > 0) {
          return !array.some(function (value, index) {
            return value !== array[0];
          });
        } else {
            return true;
          }
        }

      if(!isAllEqual(this.gsMainAddress)){
        this.$qzfMessage("请选择相同地区", 1)
        return
      }
      console.log(this.gsMainAddress);
      
      let type = '1'
      this.gsMainType.forEach(res => {
        if(res == '1') {
          type = '1'
        }else {
          type = '2'
        }
      })
      if(this.gsMainAddress[0] == 'shandong'){
        this.$confirm('因山东电子税局发票数据有误，暂停山东地区发票采集功能，请自行导入发票！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
        })
        return
      }
      this.$refs.collectGather.init(this.gsMainIds,this.listQuery.period,type,this.gsMainAddress[0])
    },
    handleUpdate(e) {
      this.$refs.collectGather.init([e.comId],this.listQuery.period,e.type,e.address)
    },
    goCheck(row){
      // this.$qzfMessage('打开中请稍后...',1);
      this.fullscreenLoading = true
      let that = this
      loginTaxInfo({id: row.comId}).then(res=>{
        let param = res.data.data.info
        axios.post('http://localhost:9111/loginTax/fpdk',param).then(res=>{
          that.fullscreenLoading = false
          if(res.data.msg != "success"){
            that.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            });
          }
        }).catch(function (e) {
          that.$alert('请先安装客户端', '提示', {
            confirmButtonText: '确定',
          });
          that.fullscreenLoading = false
          return
        });
      })
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUser(e){
      this.listQuery.userIds = []
      if(this.listQuery.userIds.length == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  height: 10px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  // display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 14px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    // display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
    text-align: left;
  }
  span {
    padding-left: 20px;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
// .details {
//   padding-left: 5px;
// }
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
</style>